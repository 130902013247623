.footer-img {
  width: 140px;
}
.footer-container {
  background: rgb(105 106 109);
    width: 100%;
}
.footer-text-headeing {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: #eccf83;
}
.footer-text {
  margin: 14px 0;
  cursor: pointer;
  letter-spacing: 0.52px;
  color: #dbdada;
  font-size: 14px;
}
.footer-col {
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: start;
}
@media (max-width: 767.98px) {
  .footer-col {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
  }
}
