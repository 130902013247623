.nav-logo {
  width: auto;
  height: 45px;
  cursor: pointer;

}
.nav-link {
  cursor: pointer !important;
  padding: 10px 14px !important;
  font-weight: 700 !important;
  color: #bfc9c2;
}
.nav-link:active {
  color: #ced08f !important;
}
.nav-link:hover {
  color: #ced08f !important;
}

.active {
  color: #bfc9c2 !important;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
  color: #ced08f!important;
}
/* .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0% !important;
  margin-top: var(--bs-dropdown-spacer);
} */
.navbarw {
  width: 96%!important;
}
.whitepaper {
  margin-top: 9px!important;
}
.whitepaper:hover {
  color: #ced08f!important;
}