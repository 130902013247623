.staked-container {
  background: #456732!important;
  height: auto;

  /* display: flex; */

  width: 100%;
  justify-content: center;
  align-items: center;
}
.box {
  border: 1px solid green;
  /* width: 100%; */

  background: #ced08f;

  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
  border-radius: 12px;
  border: 1px solid rgba(68, 67, 67, 0.47);
  padding: 50px;
}

.staked-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.staked-heading {
  font-weight: 500;
  color: #2f2f2f;

  line-height: 22px;
  font-size: 20px;
}

.staked-subheading {
  font-weight: 800;
  color: #2f2f2f ;
  line-height: 41px;
  margin-top: 25px;
  font-size: 24px;
}
@media (max-width: 768px) {
  .staked-heading {
    font-size: 20px;
  }
  .staked-subheading {
    font-size: 22px;
  }
  .input-field-3 {
    width: 300px;
  }
}
.input-field-3 {
  width: 600px !important;
  background: #456732;
  padding: 5px 15px;
  color: #fdfdfd !important;
}
.btnLInk{
  text-decoration: none !important;
  color: #2f2f2f;
}

.btnLInk{
  text-decoration: none !important;
  color: #2f2f2f;
}


.btnDetails {
  text-decoration: none;
  cursor: pointer;
  width: 140px;
  height: 40px;

  /* display: flex;

  justify-content: center;
  align-items: center; */
  border-radius: 4px;
  border: 1px solid #918256 !important;
  background: transparent;
  padding: 5px 15px;
  font-weight: 700 !important;
  color: #2f2f2f ;
  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
}

.btnDetails:hover {
  background-color: #456732;
  color: #ced3d9;
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 39%);
}



@media(max-width:425px){
  .input-field-3{
    width: 200px !important;
  }
  .btnDetails{
    margin-top: 20px !important;
  }
}