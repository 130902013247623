.bglevel-image {
  background-image: url("../../Assets/images/Background-02.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100% !important;
  height: 400px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
}
.text-level {
  font-size: 18px;
  font-weight: 800;
  color: #f7e9b9;
  line-height: 41px;
  text-transform: capitalize;
  color: linear-gradient(180deg, #f7e9b9, #f0ce75);
}
.btn-arrow {
  background-color: #ced08f;
  border: #eccf83 1px solid;
  width: 40px;
  height: 40px;
  color: #000;
  font-size: 20px;
  border-radius: 5px;
}
.btn-arrow:hover {
  color: #eccf83;
  background: #456732;
}
.level-input {
  width: 100px;
  height: 42px;
  border-radius: 5px;
  background-color: #111111;
  border: #111111 1px solid;
  color: #f0ce75;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
}
.box1 {
  width: 80%;
  height: 50px;
  background: #456732;
  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
  border-radius: 12px;
  border: 1px solid rgba(68, 67, 67, 0.47);
  padding: 20px;
}
.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.page-link {
  color: #fff;
  background-color: #a2a2a2;
  border-color: #a2a2a2;
}
.page-link,
.page-link:focus,
.disabled>.page-link {
  border: none !important;
  border-color: none !important;
  background-color: none !important;
}
.page-link:focus {
  z-index: 3;
  color: #fff !important;
  background-color: #2f2f2f00;
  outline: 0;
  box-shadow: none !important;
}
.page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  margin: 5px;
  color: #2f2f2f;
  font-weight: 500;
}
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  width: 35px;
  border-radius: 50px;
  margin: 5px;
  color: #eccf83;
  font-weight: 500;
}
.page-item:first-child:focus-visible .page-link {
  color: #fff;
  background-color: #a2a2a2;
}
.page-item .active {
  color: #fff;
  background-color: #eccf83;
}
.active>.page-link {
  color: #fff;
  background-color: #eccf83;
  border: 1px solid #eccf83;
  border-radius: 50px;
}
.disabled .page-link {
  color: #2f2f2f !important;
  background-color: #a2a2a2 !important;
}
.page-item .arrow {
  color: #111111 !important;
  background-color: #ced08f;
}
.boxLevel {
  border: 1px solid green;
  width: 100%;

  background: #ced08f;

  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
  border-radius: 12px;
  border: 1px solid rgba(68, 67, 67, 0.47);
  padding: 50px;
  display: flex;
  justify-content: center;
}
.text-details {
  color: #fff;
  font-size: 20px;
}
.sub {
  color: #2f2f2f;
}
.staked-details {
  padding-left: 90px;
}
@media (max-width: 768px) {
  .text-level {
    font-size: 14px;
  }
}


.level-overflow {
  overflow: hidden !important;
}

.button-left {
  float: left;
}
