.airDrop-contianer {
  width: 100% !important;
  height: 70vh !important;
  background: #456732!important;
}
.text-comming {
  color: #ced3d9;
  font-size: 40px;
}
.boxStakedDetail1 {
  border: 1px solid green;
  width: 50%;
  height: auto;
  background: #ced08f;
  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
  border-radius: 12px;
  border: 1px solid #918256 !important;
  padding: 10px;
  display: inline-block;
}
.btn-inner-air1 {
  cursor: pointer;
  /* width: 120px; */
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #918256 !important;
  background: transparent;
  padding: 15px 25px;
  font-weight: 700 !important;
  color: #2f2f2f;
  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
}
.btn-inner-countdown {
  cursor: pointer;
  width: 50px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #918256 !important;
  background: #0c0c0c;
  padding: 15px 25px;
  font-weight: 700 !important;
  color: #ced3d9;
}
.text-light {
  color: #eccf83 !important;
}
.text-gray {
  color: #ced3d9 !important;
}
.button-left {
  position: absolute;
}
.btn-inner-countdown {
  cursor: pointer;
  width: 50px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #918256 !important;
  background: #0c0c0c;
  padding: 15px 25px;
  font-weight: 700 !important;
  color: #ced3d9;
}