.bgStaking-image {
  background-image: url("/src/Assets/images/Background-02.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100% !important;
  height: 400px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}
.text-heading {
  font-size: 35px;
  font-weight: 800;
  color: #597c2b;
  line-height: 41px;
  color: linear-gradient(180deg, #f7e9b9, #f0ce75);
}
.text-para {
  font-size: 16px;
  max-width: 40vw;
  font-weight: 500;
  color: #2f2f2f;
  padding-bottom: 40px;
  line-height: 22px;
  text-align: left;
}
@media (max-width: 768px) {
  .text-para {
    max-width: 100vw;
  }
  
}
.text-link {
  font-weight: 500;
  color: #ced08f;
  cursor: pointer;
  line-height: 16px;
  font-size: 14px;
  text-decoration: none;
}

/* .text-heading,

.text-link{
  padding-left: 100px;
} */
