.stakedDetail-container {
  background: #456732;
  height: 400px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.stakedDetail-heading {
  font-weight: 500;
  color: #2f2f2f;
  line-height: 22px;
  font-size: 16px;
  text-align: left;
}

.boxStakedDetail {
  border: 1px solid green;
  width: 60%;
  background: #ced08f;
  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
  border-radius: 12px;
  border: 1px solid rgba(68, 67, 67, 0.47);
  padding: 50px;
}

.boldText {
  font-size: 20px;
  font-weight: 700;
  margin: 0px 5px;
  color: #456732;
}
@media (max-width: 768px) {
  .boxStakedDetail {
    width: 100%;
    padding: 10px;
  }
}
