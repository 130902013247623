.airDrop-contianer {
  width: 100% !important;
  height: 100% !important;
}
.text-comming {
  color: #fff;
  font-size: 40px;
}
.boxStakedDetail2 {
  border: 1px solid green;
  width: 80%;
  height: auto;
  background: linear-gradient(311deg, #ced08f, #ced08f);
  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
  border-radius: 12px;
  border: 1px solid #918256 !important;
  padding: 10px;
  display: inline-block;
}
.btn-inner-air1 {
  cursor: pointer;
  /* width: 120px; */
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #918256 !important;
  background: transparent;
  padding: 15px 25px;
  font-weight: 700 !important;
  color: #2f2f2f;
}
.btn-inner-countdown {
  cursor: pointer;
  width: 50px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #918256 !important;
  background: #ced08f;
  padding: 15px 25px;
  font-weight: 700 !important;
  color: #2f2f2f!important;
}
.text-light {
  color: #eccf83 !important;
}
@media (max-width: 425px) {
  .button-left {
    display: none;
  }
}
