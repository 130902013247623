.cards-container {
  background: #456732;
  width: 100%;
}
.onebox {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  background: linear-gradient(-241deg, #80A142, #597c2a, #597c2a);
  display: flex;
  flex-direction: column;
}
.outbox {
  width: 344px;
  height: 100%;
  padding: 2px;
  border: 4px solid #d1e465;
  background: linear-gradient( 135deg, rgb(207 225 89 / 13%), rgb(119 148 64 / 84%) 46%, rgb(119 148 64 / 64%) 68%, rgb(207 225 89 / 10%) );
  box-shadow: 0 20px 20px 0 rgb(0 0 0 / 39%);
}
.card-title {
  width: 135px;
  height: 31px;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  font-weight: 700;
  color: #ced08f;
  line-height: 31px;
  text-align: center;
  margin-left: -10px;
}
.card-titleWith {
  width: 180px;
  height: 31px;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  font-weight: 700;
  color: #f7e9b9;
  line-height: 31px;
  text-align: center;
  margin-left: -10px;
}
.out_top_box {
  width: 100%;
  height: 136px;
  margin-top: 40px;
  padding: 2px;
  background: #597c2a;
  border: 3px solid #d2e45e;
}
.top_box {
  width: 100%;
  height: 100%;
 /* background: linear-gradient(132deg, #2f2f2f, #0b0a0a); */
  padding: 11px 10px;
}
.imgTLogo {
  width: 92px;
  padding-top: 12px;
}
.text-staked,
.doller-staked,
.value-staked {
  font-weight: 700;
  color: #ced08f;
  line-height: 22px;
  display: flex;
  justify-content: start;
}
.text-staked {
  font-size: 16px;
  color: #ced08f;
}

.doller-staked {
  font-size: 12px;
}
.value-staked {
  font-size: 30px;
  color: #2f2f2f;
}
.text-pool {
  font-size: 16px;
  color: #2f2f2f!important;
  text-decoration: none;
}
a:hover {
  color: #ced3d9;
}
.value-afterPool {
  font-weight: 700;
  font-size: 20px;
  color: #ced3d9;
  line-height: 22px;
  display: flex;
  justify-content: start;
}
.value-afterStaked {
  font-weight: 700;
  font-size: 15px;
  color: #ced3d9;
  line-height: 22px;
  display: flex;
  justify-content: start;
}
.img-small {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.btn-inner {
  cursor: pointer;
  width: 130px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #d2e45e !important;
  background: transparent;
  padding: 5px 10px;
  font-weight: 700 !important;
  color: #ced3d9;
}
.inner-doller {
  color: #8f8e8e !important;
}
.sr-para,
.sr-para2 {
  font-weight: 500;
  color: #ced08f;
  line-height: 22px;
  font-size: 12px;
}
.sr-para {
  font-size: 12px;
}
.sr-para2 {
  font-size: 14px;
}
@media (max-width: 1024px) {
  .value-staked {
    font-size: 25px;
  }
  .text-pool {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .value-staked {
    font-size: 18px;
  }
  .text-pool {
    font-size: 12px;
  }
}
.input-field {
  width: 100px !important;
  border: 3px solid #d2e45e !important;
  background: transparent;
  padding: 5px 15px;
  height: 40px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.input-field-2 {
  width: 400px !important;
  border: 3px solid #d2e45e !important;
  background: transparent;
  padding: 5px 15px;
  color: #ced3d9 !important;
  height: 38px;
}


@media (max-width: 425px) {

  .input-field-2 {
    width: 150px !important;
  }
}


